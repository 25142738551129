<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>

    <el-row class="content-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="课程管理" name="first">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="课程标题">
              <el-input v-model="title" clearable></el-input>
            </el-form-item>
            <el-form-item label="所属机构">
              <el-select
                filterable
                v-model="mechanism_id"
                clearable
                remote
                :remote-method="searchOrgName"
                @focus="searchOrgName"
              >
                <el-option
                  v-for="(option, index) in mechanism_name_list"
                  :key="index"
                  :value="option.id"
                  :label="option.mechanism_name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="search('course')">查询</el-button>
              <el-button
                @click="addDialog = true"
                type="primary"
                style="float: right"
                >新增</el-button
              >
            </el-form-item>
          </el-form>

          <el-table :data="courseList" v-loading="isLoading">
            <el-table-column label="课程id" prop="id"> </el-table-column>
            <el-table-column label="课程标题" prop="title"> </el-table-column>
            <el-table-column label="课程类目" prop="categories_child">
            </el-table-column>
            <el-table-column
              label="课程总节数"
              prop="course_num"
            ></el-table-column>
            <el-table-column
              label="课时长(min)"
              prop="length_of_lesson"
            ></el-table-column>
            <el-table-column
              label="课程购买数"
              prop="pay_num"
            ></el-table-column>
            <el-table-column
              label="剩余可兑换数"
              prop="restricted_exchange_count"
            ></el-table-column>
            <el-table-column label="市场价" prop="amout"></el-table-column>
            <el-table-column
              label="单节结算价"
              prop="settlement_cash"
            ></el-table-column>
            <el-table-column
              label="天赋课"
              prop="settlement_course"
            ></el-table-column>
            <el-table-column label="正课" prop="course_num"></el-table-column>
            <el-table-column
              label="所属机构"
              width="150"
              prop="map.masterMechanismEntity.mechanism_name"
            ></el-table-column>
            <el-table-column
              label="上架时间"
              width="200"
              prop="create_time"
            ></el-table-column>
            <el-table-column label="卡类型" prop="card_type"></el-table-column>
            <el-table-column label="权益点数" prop="prenuim"></el-table-column>
            <el-table-column label="是否推荐" prop="is_recommend">
              <template slot-scope="scope">
                {{ scope.row.is_recommend ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: red"
                  >审核拒绝</span
                >
                <span v-if="scope.row.status == 2" style="color: green"
                  >已通过</span
                >
                <!-- <span v-if="scope.row.status == 3" style="color: red">审核拒绝</span> -->
                <span v-if="scope.row.status == 4" style="color: orange"
                  >待审核</span
                >
                <span v-if="scope.row.status == 5" style="color: red"
                  >下架</span
                >
                <span v-if="scope.row.status == 6">冻结</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="" width="420">
              <template slot-scope="scope">
                <div style="display: flex">
                  <!-- <el-button size="small">下架</el-button> -->
                  <el-button
                    size="small"
                    type="primary"
                    @click="openExchange(scope.row)"
                    >更改可兑换数</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    @click="toCouDetails(scope.row)"
                    >查看详情</el-button
                  >
                  <el-button
                    size="small"
                    type="warning"
                    @click="openUpdate(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="small"
                    type="danger"
                    v-if="scope.row.status == 2"
                    @click="frozen(scope.row)"
                    >冻结
                  </el-button>
                  <el-button
                    size="small"
                    type="success"
                    v-if="scope.row.status == 6"
                    @click="unfrozen(scope.row)"
                    >解结
                  </el-button>
                  <!-- <el-button size="small" type="success" v-if="scope.row.status == 4 || scope.row.status == 1"
                @click="pass(scope.row)">通过审核
              </el-button>
              <el-button size="small" type="danger" v-if="scope.row.status == 4" @click="refuse(scope.row)">审核未通过
              </el-button> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <paging-fy
            @currentPageChange="currentPageChange"
            :currentPage="currentPage"
            :total="courseTotal"
          ></paging-fy>
        </el-tab-pane>
        <el-tab-pane label="服务管理" name="second">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="服务标题">
              <el-input v-model="title" clearable></el-input>
            </el-form-item>
            <el-form-item label="所属机构">
              <el-select
                filterable
                v-model="mechanism_id"
                clearable
                remote
                :remote-method="searchOrgName"
                @focus="searchOrgName"
              >
                <el-option
                  v-for="(option, index) in mechanism_name_list"
                  :key="index"
                  :value="option.id"
                  :label="option.mechanism_name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="status" clearable>
                <el-option label="已通过" :value="2"></el-option>
                <el-option label="已拒绝" :value="1"></el-option>
                <el-option label="待审核" :value="4"></el-option>
                <el-option label="已下架" :value="5"></el-option>
                <el-option label="已冻结" :value="6"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="search('service')">查询</el-button>
              <el-button
                @click="addDialog = true"
                type="primary"
                style="float: right"
                >新增</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <el-dialog
      :visible="addDialog"
      title="新增课程"
      :destroy-on-close="true"
      :before-close="handleClose"
      :width="ks ? '95%' : '80%'"
    >
      <el-form
        :model="form"
        label-width="auto"
        style="display: flex; justify-content: space-around; flex-wrap: wrap"
        ref="myForm"
        :rules="rules"
      >
        <div :style="ks ? 'width: 100%' : 'width: 35%'">
          <el-form-item label="商品类型">
            <el-select v-model="course_type">
              <!-- <el-option value="standard" label="标准课"></el-option> -->
              <el-option value="haimacourse" label="海马课包"></el-option>
              <el-option value="telent" label="天赋课"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称" prop="title">
            <el-input v-model="form.title" :placeholder="temp.title"></el-input>
          </el-form-item>
          <el-form-item
            :label="
              '课节数' +
              (parseInt(course_num) + parseInt(form.settlement_course)
                ? '【' +
                  (parseInt(course_num) + parseInt(form.settlement_course)) +
                  '】'
                : '')
            "
          >
            <el-row style="display: flex" v-if="course_type == 'haimacourse'">
              <el-col :span="12">
                <el-form-item
                  label-width="6rem"
                  label="天赋课"
                  prop="settlement_course"
                >
                  <el-input
                    v-model="form.settlement_course"
                    :placeholder="temp.settlement_course"
                  >
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label-width="6rem" label="正课" prop="course_num">
                  <el-input
                    v-model="course_num"
                    :placeholder="
                      temp.course_num
                        ? temp.course_num - temp.settlement_course
                        : ''
                    "
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-input
              v-model="form.course_num"
              :placeholder="temp.course_num"
              v-if="!(course_type == 'haimacourse')"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="核销课节数">
            <el-input
              v-model="form.deduction_course_num"
              placeholder="默认1课节"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属机构" prop="mechanism_id">
            <el-select
              v-model="form.mechanism_id"
              remote
              :remote-method="searchOrgName"
              filterable
              clearable
              :placeholder="
                mechanism_name(temp.mechanism_id).mechanism_name || '请选择机构'
              "
            >
              <el-option
                v-for="(item, index) in mechanism_name_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程类目" prop="categories_child">
            <el-select
              v-model="form.categories_child"
              :placeholder="temp.categories_child"
              clearable
            >
              <el-option
                v-for="(item, index) in categories_child_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否推荐">
            <el-checkbox v-model="form.is_recommend"></el-checkbox>
          </el-form-item>

          <el-form-item label="排课类型" prop="appointment_type">
            <el-radio-group v-model="form.appointment_type">
              <el-radio label="appointment">预约</el-radio>
              <el-radio label="fixed_scheduling">固定</el-radio>
            </el-radio-group>
          </el-form-item>
          <span>
            <el-form-item
              label="适用会员卡"
              v-if="course_type != 'telent'"
              prop="card_type"
            >
              <el-cascader
                v-model="card_type"
                :options="cardLevel"
                remote
                @focus="getCardLevel"
                :props="{
                  label: 'member_name',
                  value: 'member_name',
                  multiple: true,
                }"
                :show-all-levels="false"
                @change="changeCardType"
                :placeholder="temp.card_type"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="单节市场价"
              v-if="course_type != 'telent'"
              prop="amout"
            >
              <el-input
                v-model="form.amout"
                :placeholder="temp.amout"
              ></el-input>
              <span>元</span>
            </el-form-item>
            <!-- <el-form-item label="折扣价" v-if="course_type != 'telent'">
              <el-input v-model="form.discount_amout" :placeholder="temp.discount_amout">
              </el-input><span class="form_text">元</span>
            </el-form-item> -->
            <el-form-item
              label="单节结算价"
              v-if="course_type != 'telent'"
              prop="settlement_cash"
            >
              <el-input
                v-model="form.settlement_cash"
                :placeholder="temp.settlement_cash"
              >
              </el-input>
            </el-form-item>
            <span v-if="course_type != 'telent'">
              <el-form-item label="单节权益点数" v-if="course_type != 'telent'">
                <span>{{
                  form.pay_point !== null && form.pay_point !== undefined
                    ? form.pay_point
                    : "请先选择适用会员卡"
                }}</span>
                <el-button @click="getPoint()">计算</el-button>
              </el-form-item>
              <!-- <el-form-item label="尊享卡权益总点数" v-if="course_type !='telent'">
                <span>{{form.premium_point||temp.premium_point|| "请输入/选择课节数、适用会员卡和结算价"}}</span>
              </el-form-item> -->
            </span>
            <el-form-item label="商品提示">
              <el-input v-model="form.label"></el-input>
            </el-form-item>
          </span>
          <span>
            <el-form-item label="总市场价" v-if="course_type == 'telent'">
              <el-input
                v-model="form.amout"
                :placeholder="temp.amout"
              ></el-input>
              <span>元</span>
            </el-form-item>
            <el-form-item label="标价" v-if="course_type == 'telent'">
              <el-input
                v-model="form.default_discount_amout"
                :placeholder="temp.discount_amout"
              >
              </el-input
              ><span class="form_text">元</span>
            </el-form-item>
          </span>
        </div>
        <div :style="ks ? 'width: 100%' : 'width: 25%'">
          <el-form-item label="封面" required prop="face_url">
            <avatar-uploader
              @getImgUrl="getCover"
              :url="temp.face_url"
              :clear="if_clear"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="介绍" required>
            <el-input
              type="textarea"
              v-model="form.introduction_content"
              :placeholder="temp.introduction_content"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="商品介绍图片" required>
            <images-uploader
              @getImgUrl="getProductImg"
              :urls="temp.introduction_url"
              :clear="if_clear"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="团课人数" required="" prop="connect_peoplenum">
            <el-input
              v-model="form.connect_peoplenum"
              :placeholder="temp.connect_peoplenum"
            ></el-input
            ><span>人</span>
          </el-form-item>
          <el-form-item label="课时长" required="" prop="length_of_lesson">
            <el-input
              v-model="form.length_of_lesson"
              :placeholder="temp.length_of_lesson"
            ></el-input
            ><span>分钟</span>
          </el-form-item>
          <el-form-item label="有效期" required="" prop="duration">
            <el-input
              v-model="form.duration"
              :placeholder="temp.duration"
            ></el-input
            ><span>月</span>
          </el-form-item>
          <el-form-item label="适用年龄" required="" prop="apply_age">
            <el-input v-model="form.apply_age"></el-input>
          </el-form-item>
          <el-form-item label="教龄" required="" prop="school_age">
            <el-select v-model="form.school_age" :placeholder="temp.school_age">
              <el-option
                v-for="(item, index) in school_age_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <span
            v-if="course_type == 'haimacourse' && form.settlement_course != 0"
          >
            <el-form-item label="选择天赋课" v-if="!if_new" prop="bind_id">
              <el-select
                remote
                :remote-method="getExperienceCourse"
                @focus="getExperienceCourse"
                v-model="form.bind_id"
                placeholder="请选择天赋课"
                clearable
              >
                <el-option
                  v-for="item in experience_course"
                  :key="item.id"
                  :value="item.id"
                  :label="item.title"
                >
                </el-option>
                <el-option v-if="!haveTelente">暂无天赋课</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否新增天赋课">
              <el-checkbox v-model="if_new"></el-checkbox>
            </el-form-item>
          </span>
        </div>
        <div style="width: 30%" v-if="if_new">
          <div class="sec-title">新增天赋课</div>
          <el-form-item label="天赋课标题" v-if="if_new">
            <el-input v-model="form1.title1"></el-input>
          </el-form-item>
          <el-form-item label="天赋课市场价" v-if="if_new">
            <el-input v-model="form1.original_price"></el-input>
            <span>元</span>
          </el-form-item>
          <el-form-item label="天赋课标价" v-if="if_new">
            <el-input v-model="form1.default_discount_price1"></el-input>
            <span>元</span>
          </el-form-item>
          <!-- <el-form-item label="天赋课课节数" v-if="if_new">
                <span>{{form.settlement_course}}</span>
              </el-form-item> -->
          <el-form-item label="天赋课封面" v-if="if_new">
            <avatar-uploader
              :clear="if_new"
              @getImgUrl="getTelentFace"
              :url="form.face_url"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="天赋课介绍" v-if="if_new">
            <el-input
              type="textarea"
              v-model="form1.categories_child_string"
              :placeholder="form.introduction_content"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="天赋课图片简介" v-if="if_new">
            <images-uploader
              :clear="if_new"
              @getImgUrl="getTelentIntroduce"
              :urls="form.introduction_url"
            >
            </images-uploader>
          </el-form-item>
        </div>
        <!-- <div style="width:25%" v-if="course_type =='telent'">
          <div class="sec-title" style="color:#ff4400">天赋课所需信息</div>
          <el-form-item label="市场价" required>
            <el-input v-model="form.amout" :placeholder="temp.amout"></el-input>
            <span>元</span>
          </el-form-item>
          <el-form-item label="折扣价">
            <el-input v-model="form.discount_amout" :placeholder="temp.discount_amout">
            </el-input><span class="form_text">元</span>
          </el-form-item>
        </div> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm()">确 定</el-button>
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      :visible="updateDialog"
      title="修改课程"
      :destroy-on-close="true"
      :before-close="handleClose"
      width="90%"
    >
      <el-form :model="form" label-width="auto" class="xgk" ref="myForm">
        <div class="xgk1">
          <el-form-item label="商品名称">
            <el-input v-model="form.title" :placeholder="temp.title"></el-input>
          </el-form-item>
          <el-form-item
            :label="
              '课节数' +
              (parseInt(course_num) + parseInt(form.settlement_course)
                ? '【' +
                  (parseInt(course_num) + parseInt(form.settlement_course)) +
                  '】'
                : '')
            "
          >
            <el-row
              style="display: flex"
              v-if="updateDialog && course_type != 'telent'"
            >
              <el-col :span="12">
                <el-form-item
                  label-width="6rem"
                  label="天赋课"
                  prop="settlement_course"
                >
                  <el-input
                    v-model="form.settlement_course"
                    :placeholder="temp.settlement_course"
                  >
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label-width="6rem" label="正课" prop="course_num">
                  <el-input
                    v-model="course_num"
                    :placeholder="
                      temp.course_num
                        ? temp.course_num - temp.settlement_course
                        : ''
                    "
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-input
              v-model="form.course_num"
              :placeholder="temp.course_num"
              v-if="course_type == 'telent'"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="核销课节数">
            <el-input
              :placeholder="temp.deduction_course_num"
              v-model="form.deduction_course_num"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属机构" prop="mechanism_id">
            <el-select
              v-model="form.mechanism_id"
              remote
              :remote-method="searchOrgName"
              filterable
              clearable
              :placeholder="
                mechanism_name(temp.mechanism_id).mechanism_name || '请选择机构'
              "
            >
              <el-option
                v-for="(item, index) in mechanism_name_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程类目" prop="categories_child">
            <el-select
              v-model="form.categories_child"
              :placeholder="temp.categories_child"
              clearable
            >
              <el-option
                v-for="(item, index) in categories_child_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否推荐">
            <el-checkbox v-model="form.is_recommend"></el-checkbox>
          </el-form-item>

          <el-form-item label="排课类型" prop="appointment_type">
            <el-radio-group v-model="form.appointment_type">
              <el-radio label="appointment">预约</el-radio>
              <el-radio label="fixed_scheduling">固定</el-radio>
            </el-radio-group>
          </el-form-item>
          <span>
            <el-form-item
              label="适用会员卡"
              v-if="course_type != 'telent'"
              prop="card_type"
            >
              <el-cascader
                v-model="card_type"
                :options="cardLevel"
                remote
                @focus="getCardLevel"
                :props="{
                  label: 'member_name',
                  value: 'member_name',
                  multiple: true,
                }"
                :show-all-levels="false"
                @change="changeCardType"
                :placeholder="temp.card_type"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="单节市场价" prop="amout">
              <el-input
                v-model="form.amout"
                :placeholder="temp.amout / (temp.course_num / 2)"
              ></el-input>
              <span>元</span>
            </el-form-item>
            <el-form-item label="标价" v-if="course_type == 'telent'">
              <el-input
                v-model="form.default_discount_price"
                :placeholder="temp.default_discount_price"
              >
              </el-input
              ><span class="form_text">元</span>
            </el-form-item>
            <!-- <el-form-item label="折扣价" v-if="course_type != 'telent'">
              <el-input v-model="form.discount_amout" :placeholder="temp.discount_amout">
              </el-input><span class="form_text">元</span>
            </el-form-item> -->
            <el-form-item
              label="单节结算价"
              v-if="course_type != 'telent'"
              prop="settlement_cash"
            >
              <el-input
                v-model="form.settlement_cash"
                :placeholder="temp.settlement_cash"
              >
              </el-input>
            </el-form-item>
            <span v-if="course_type != 'telent'">
              <el-form-item label="单节权益点数" v-if="course_type != 'telent'">
                <span>{{
                  form.premium_point ||
                  (temp.premium_point / (temp.course_num / 2)).toFixed(1) ||
                  0
                }}</span>
              </el-form-item>
              <!-- <el-form-item label="尊享卡权益总点数" v-if="course_type !='telent'">
                <span>{{form.premium_point||temp.premium_point|| 0}}</span>
              </el-form-item> -->
            </span>
          </span>
        </div>
        <div class="xgk1">
          <el-form-item label="封面" required prop="face_url">
            <avatar-uploader
              @getImgUrl="getCover"
              :url="temp.face_url"
              :clear="if_clear"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="介绍">
            <el-input
              type="textarea"
              v-model="form.introduction_content"
              :placeholder="temp.introduction_content"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="商品介绍图片">
            <images-uploader
              @getImgUrl="getProductImg"
              :urls="temp.introduction_url"
              :clear="if_clear"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="团课人数" prop="connect_peoplenum">
            <el-input
              v-model="form.connect_peoplenum"
              :placeholder="temp.connect_peoplenum"
            ></el-input
            ><span>人</span>
          </el-form-item>
          <el-form-item label="课时长" prop="length_of_lesson">
            <el-input
              v-model="form.length_of_lesson"
              :placeholder="temp.length_of_lesson"
            ></el-input
            ><span>分钟</span>
          </el-form-item>
          <el-form-item label="有效期" prop="duration">
            <el-input
              v-model="form.duration"
              :placeholder="temp.duration"
            ></el-input
            ><span>月</span>
          </el-form-item>
          <el-form-item label="适用年龄" prop="apply_age">
            <el-input
              v-model="form.apply_age"
              :placeholder="temp.apply_age"
            ></el-input>
          </el-form-item>
          <el-form-item label="教龄" prop="school_age">
            <el-select v-model="form.school_age" :placeholder="temp.school_age">
              <el-option
                v-for="(item, index) in school_age_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="update()">确 定</el-button>
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible="exchangeShow"
      title="更新可兑换次数"
      :before-close="handleClose"
    >
      <div>
        <label for="">可兑换次数</label>
        <el-input v-model="restricted_exchange_count"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateExchange()">确 定</el-button>
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ks: "",
      nav: {
        firstNav: "机构管理中心",
        secondNav: "商品管理",
      },
      type: "mechanism_offline",
      isLoading: true,
      if_clear: false,
      if_new: false,
      activeName: "first",
      // 课程列表
      currentPage: 1,
      courseTotal: null,
      courseList: [],
      // 搜索
      mechanism_id: null,
      experience_course: [],
      status: null,
      mechanism_name_list: [],
      cancelToken: null,
      mechanism_type: "",
      categories_child_list: [],
      haveTelente: true,
      exchangeShow: false,
      // master_id: null,
      // master_name_list: [],
      title: null,
      master_name: "",
      course_type: "haimacourse",
      cardLevel: [],
      allCardLevel: [],
      // 课程类型图表
      courseType: [],
      timeoutID: null,
      temp: {},
      restricted_exchange_count: null,
      form: {},
      form1: {},
      venues_list: [],
      addDialog: false,
      card_type: [],
      updateDialog: false,
      age_type_list: [],
      school_age_list: [],
      course_num: null,
      cancel: null,
      rules: {
        title: [
          { required: true, message: "标题不能为空!", trigger: "blur" },
          { required: true, message: "标题不能为空!", trigger: "change" },
        ],
        mechanism_id: [
          // { required: true, message: '请选择机构!', trigger: 'blur' },
          { required: true, message: "请选择机构!", trigger: "change" },
        ],

        categories_child: [
          { required: true, message: "请选择类目!", trigger: "change" },
        ],
        appointment_type: [
          { required: true, message: "请选择排课类型!", trigger: "change" },
        ],
        card_type: [
          { required: true, message: "请选择卡类型!", trigger: "change" },
        ],
        amout: [
          { required: true, message: "请填入市场价格!", trigger: "change" },
          { required: true, message: "请填入市场价格!", trigger: "blur" },
        ],
        settlement_cash: [
          { required: true, message: "请填入结算价格!", trigger: "blur" },
          { required: true, message: "请填入结算价格!", trigger: "change" },
        ],
        settlement_course: [
          { required: true, message: "请填入天赋课数!", trigger: "blur" },
          { required: true, message: "请填入天赋课数!", trigger: "change" },
        ],
        course_num: [
          { required: true, message: "请填入正课数!", trigger: "blur" },
          { required: true, message: "请填入正课数!", trigger: "change" },
        ],
        face_url: [
          { required: true, message: "请上传封面!", trigger: "change" },
        ],
        connect_peoplenum: [
          { required: true, message: "请填入最大人数!", trigger: "change" },
          { required: true, message: "请填入最大人数!", trigger: "blur" },
        ],
        length_of_lesson: [
          { required: true, message: "请填入课时长!", trigger: "change" },
          { required: true, message: "请填入课时长!", trigger: "blur" },
        ],
        duration: [
          { required: true, message: "请填入有效期!", trigger: "change" },
          { required: true, message: "请填入有效期!", trigger: "blur" },
        ],
        apply_age: [
          { required: true, message: "请选择适用年龄!", trigger: "change" },
        ],
        school_age: [
          { required: true, message: "请选择适用教龄!", trigger: "change" },
        ],
      },
      rules2: {},
    };
  },
  watch: {
    course_type(newV, oldV) {
      this.form = {};
      this.course_num = null;
      this.if_clear = true;
      this.if_new = false;
      setTimeout(() => {
        this.if_clear = false;
      }, 10);
      // this.$set(this.form, 'settlement_cash', null)
      // if (newV == 'haimacourse') {
      // }
    },
    "form.settlement_course": {
      handler(newV, oldV) {
        if (!newV === 0) {
          this.if_new = false;
        }
      },
    },
    course_num(newV, oldV) {
      if (newV) {
        this.$set(
          this.form,
          "course_num",
          parseInt(this.form.settlement_course || this.temp.settlement_course) +
            parseInt(newV)
        );
      } else {
        this.$set(this.form, "course_num", null);
      }
    },
    // 'form.amout': {
    //   handler(newV, oldV) {
    //     if (newV) {
    //       if (this.form.settlement_cash || this.temp.settlement_cash) {
    //         // this.form.pay_point = '正在计算权益点数...'
    //         // this.form.premium_point = '正在计算权益点数...'
    //         if (this.timeoutID) {
    //           clearTimeout(this.timeoutID)
    //         }
    //         this.timeoutID = setTimeout(() => {
    //           this.getPoint()
    //         }, 300)
    //       }
    //     }
    //   },
    // },
    // 'form.settlement_cash': {
    //   handler(newV, oldV) {
    //     console.log(newV)
    //     if (newV) {
    //       console.log('newV')
    //       if (this.form.amout || this.temp.amout) {
    //         // this.form.pay_point = '正在计算权益点数...'
    //         // this.form.premium_point = '正在计算权益点数...'
    //         if (this.timeoutID) {
    //           clearTimeout(this.timeoutID)
    //         }
    //         this.timeoutID = setTimeout(() => {
    //           this.getPoint()
    //         }, 300)
    //       }
    //     }
    //   },
    // },
    if_new(newV, oldV) {
      if (!newV) {
        this.form1 = {};
      } else {
        this.$set(this.form, "bind_id", null);
        this.$set(this.form1, "title1", this.form.title);
        this.$set(this.form1, "default_discount_price1", 99);
        this.$set(
          this.form1,
          "categories_child_String",
          this.form.introduction_content
        );
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab);
      if (tab.index == 0) {
        this.type = "mechanism_offline";
        this.getCourseLsit();
      } else if (tab.index == 1) {
        this.type = "场馆";
        this.getCourseLsit();
      }
    },
    openExchange(item) {
      this.temp = item;
      this.exchangeShow = true;
    },
    updateExchange() {
      let url = "/user/masterSetPrice/updateExchangeCount";
      this.$axios
        .post(url, {
          id: this.temp.id,
          restricted_exchange_count: this.restricted_exchange_count,
          mechanism_id: this.temp.mechanism_id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "更新成功!",
              type: "success",
            });
            this.exchangeShow = false;
            this.getCourseLsit();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    getPoint() {
      if (this.form.amout || this.temp.amout) {
        if (this.form.settlement_cash || this.settlement_cash.amout) {
          let url = "/user/masterSetPrice/querySettlePoint";
          this.$axios
            .get(url, {
              params: {
                card_type:
                  this.form.card_type || this.temp.card_type
                    ? this.form.card_type || this.temp.card_type
                    : "优学卡",
                amout:
                  this.form.amout ||
                  this.temp.amout / (this.temp.course_num / 2) ||
                  0,
                settlement_cash:
                  this.form.settlement_cash || this.temp.settlement_cash || 0,
                type: "mechanism_offline",
              },
            })
            .then((res) => {
              this.$set(this.form, "pay_point", res.data.data.point);
              this.$set(this.form, "premium_point", res.data.data.point);
            });
        }
      }
    },
    submitForm() {
      let _this = this;
      console.log(this.$refs);
      this.$refs.myForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          console.log("success");
          _this.addNewCourse();
        } else {
          console.log("fail");
          _this.$message({
            type: "error",
            message: "请填写必要信息!",
          });
        }
      });
    },
    search() {
      this.currentPage = 1;
      this.getCourseLsit();
    },
    getTelentFace(v) {
      this.form1.face_url1 = v;
    },
    getTelentIntroduce(v) {
      this.form1.introduction_url1 = v;
    },
    getCover(v) {
      this.form.face_url = v;
    },
    mechanism_name(id) {
      let a = this.mechanism_name_list.find((item, index, arr) => {
        return item.id == id;
      });
      if (a) {
        return a.mechanism_name;
      } else return {};
    },

    getProductImg(v) {
      // console.log(v);
      this.$set(this.form, "introduction_url", v);
    },
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: "课程",
          },
        })
        .then((res) => {
          this.categories_child_list = res.data.data;

          // this.typeTotal = res.data.data.total;
        });
    },
    changeGoodType(v) {
      this.mechanism_id = null;
      this.mechanism_name_list = [];
      this.searchOrgName();
    },
    getVenuesList(query) {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: this.status ? this.status : null,
            type: "场馆",
            categories: "体育运动",
            source: "体育宝",
            title: typeof query == "string" && query != "" ? query : null,
            // is_school: false,
            // master_id: this.master_id? this.master_id:null,
            mechanism_id: this.form.mechanism_id
              ? this.form.mechanism_id
              : null,
          },
        })
        .then((res) => {
          this.venues_list = res.data.data.rows;
        })
        .catch((err) => {});
    },
    // 课程列表分页获取
    getCourseLsit() {
      this.isLoading = true;
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: 4,
            type: this.type,
            categories: "体育运动",
            source: "体育宝",
            pageSize: 10,
            // is_school: false,
            currentPage: this.currentPage,
            title: this.title ? this.title : null,
            // master_id: this.master_id? this.master_id:null,
            mechanism_id: this.mechanism_id ? this.mechanism_id : null,
            // binding_venues_id: 0,
          },
        })
        .then((res) => {
          this.courseList = res.data.data.rows;
          this.courseTotal = res.data.data.total;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getCourseLsit();
    },
    addNewCourse() {
      let url = "/user/masterSetPrice/insert";
      this.if_clear = false;
      // console.log(this.form);
      // this.form.discount_amout = this.form.amout * this.form.discount
      // price_list: '0-10-1000,11-20-2000',
      if (this.course_type == "haimacourse") {
        this.form.course_num =
          parseInt(this.course_num) + parseInt(this.form.settlement_course);
      } else if (this.course_type == "telent") {
        this.form.course_num = this.form.course_num ? this.form.course_num : 2;
      }
      if (!this.form.introduction_content && !this.form.introduction_url) {
        return this.$message({
          type: "error",
          message: "简介和简介图片至少填一个!",
        });
      }
      let data = {
        ...this.form,
        ...this.form1,
      };
      data.pay_point = this.form.pay_point * (this.form.course_num / 2);
      data.amout = this.form.amout * (this.form.course_num / 2);
      data.premium_point = this.form.premium_point * (data.course_num / 2);
      data.binding_venues_id = 0;
      data.card_type = this.form.card_type ? this.form.card_type : "优学卡";
      data.categories = "体育运动";
      data.type = "mechanism_offline";
      data.commodity_type = "正式课";
      data.status = 2;
      data.default_discount_price = this.form.card_type == "优学卡" ? 99 : 0;
      this.$axios
        .post(url, {
          // ...this.form,
          ...data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "添加成功!" });
            this.addDialog = false;
            this.if_clear = true;
            this.form = {};
            this.course_num = null;
            this.card_type = [];
            this.mechanism_id = null;
            this.getCourseLsit();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    pass(row) {
      let url = "/user/masterSetPrice/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getCourseLsit();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    refuse(row) {
      let url = "/user/masterSetPrice/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getCourseLsit();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    openUpdate(row) {
      this.temp = row;
      this.$set(this.form, "is_discount", row.is_discount);
      this.$set(this.form, "is_recommend", row.is_recommend);
      this.$set(this.form, "appointment_type", row.appointment_type);
      if (row.card_type == "优学卡") {
        this.course_type = "telent";
      } else {
        this.course_type = "haimacourse";
      }
      this.mechanism_type = row.type;
      this.updateDialog = true;
    },
    update() {
      let url = "/user/masterSetPrice/update";
      this.if_clear = false;
      let data = { ...this.form };
      data.id = this.temp.id;
      data.is_recommend =
        this.temp.is_recommend != this.form.is_recommend
          ? this.form.is_recommend
          : null;
      data.appointment_type =
        this.temp.appointment_type != this.form.appointment_type
          ? this.form.appointment_type
          : null;
      data.amout =
        this.form.amout || data.course_num
          ? this.form.amout * (data.course_num / 2 || this.temp.course_num / 2)
          : null;
      data.premium_point =
        this.form.premium_point || data.course_num
          ? this.form.premium_point *
            ((data.course_num || this.temp.course_num) / 2)
          : null;
      data.pay_point =
        this.form.pay_point || data.course_num
          ? this.form.pay_point *
            ((data.course_num || this.temp.course_num) / 2)
          : null;

      this.$axios
        .post(url, {
          ...data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.updateDialog = false;
            this.getCourseLsit();
            this.if_clear = true;
            this.form = {};
            this.course_num = null;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    changeCardType(v) {
      let list = [];
      v.forEach((item) => {
        list.push(item[0]);
      });
      this.$set(this.form, "card_type", list.join(","));
    },
    frozen(row) {
      let url = "/user/masterSetPrice/update";
      this.if_clear = false;
      this.$axios
        .post(url, {
          id: row.id,
          status: 6,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.updateDialog = false;
            this.getCourseLsit();
            this.if_clear = true;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    unfrozen(row) {
      let url = "/user/masterSetPrice/update";
      this.if_clear = false;
      this.$axios
        .post(url, {
          id: row.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.updateDialog = false;
            this.getCourseLsit();
            this.if_clear = true;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    getExperienceCourse(query) {
      let url = "/user/masterSetPrice/queryByMessage";
      if (!this.form.mechanism_id) {
        return this.$message({
          type: "error",
          message: "请先选择要绑定的机构!",
        });
      }
      this.$axios
        .get(url, {
          params: {
            status: this.status ? this.status : null,
            type: this.type,
            categories: "体育运动",
            source: "体育宝",
            card_type: "优学卡",
            pageSize: 10,
            // is_school: false,
            // master_id: this.master_id? this.master_id:null,
            title: typeof query == "string" ? query : null,
            mechanism_id: this.form.mechanism_id
              ? this.form.mechanism_id
              : null,
            // binding_venues_id: 0,
          },
        })
        .then((res) => {
          this.experience_course = res.data.data.rows;
          if (!this.experience_course.length) {
            this.haveTelente = false;
          }
        })
        .catch((err) => {});
    },
    getCardLevel() {
      let url = "/user/userMemberLevel/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 99,
            platform: "体育宝",
            status: 1,
            type: 2,
          },
        })
        .then((res) => {
          this.cardLevel = res.data.data.rows.filter((item) => {
            return item.parent_id != 0;
          });
          // let z = this.cardLevel.findIndex((item) => {
          //   return item.member_name == '尊享卡'
          // })
          // console.log(z)
          // if (z != -1) {
          //   this.$set(this.cardLevel[z], 'disabled', true)
          // }
        });
    },
    // getCardType(v) {
    //   console.log(v)
    //   let s = ''
    //   let i = v.findIndex((item) => {
    //     return item[0] == '专享卡'
    //   })
    //   let t = v.findIndex((item) => {
    //     return item[0] == '优学卡'
    //   })
    //   let j = v.findIndex((item) => {
    //     return item[0] == '尊享卡'
    //   })
    //   if (i != -1) {
    //     console.log(i, '专享卡')
    //     let m = this.cardLevel.findIndex((item) => {
    //       return item.member_name == '优学卡'
    //     })
    //     this.$set(this.cardLevel[m], 'disabled', true)
    //   } else if (t != -1) {
    //     let m = this.cardLevel.findIndex((item) => {
    //       return item.member_name == '专享卡'
    //     })
    //     this.$set(this.cardLevel[m], 'disabled', true)
    //   } else if (v.length == 0) {
    //     for (let index = 0; index < this.cardLevel.length; index++) {
    //       if (this.cardLevel[index].member_name != '尊享卡') {
    //         this.$set(this.cardLevel[index], 'disabled', false)
    //       }
    //     }
    //   }
    //   for (let i = 0; i < v.length; i++) {
    //     s += v[i][v[i].length - 1] + (i == v.length - 1 ? '' : ',')
    //   }
    //   this.form.card_type = s
    // },
    getAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 99,
            currentPage: 1,
            platform: "年级",
          },
        })
        .then((res) => {
          this.age_type_list = res.data.data.rows;
        });
    },
    getSchoolAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 99,
            currentPage: 1,
            platform: "学龄",
          },
        })
        .then((res) => {
          this.school_age_list = res.data.data.rows;
        });
    },
    // getCourseType() {
    //   let url = "/user/masterSetPrice/queryYesterdayCount";
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         status: 2,
    //         source: "体育宝",
    //         categories: "体育运动",
    //       },
    //     })
    //     .then((res) => {
    //       let temp = [];
    //       for (let item in res.data.data.totalMap) {
    //         // console.log(item);
    //         if (item != "total") {
    //           temp.push({
    //             name: item,
    //             value: res.data.data.totalMap[item],
    //           });
    //         }
    //       }
    //       this.$refs.chart.drawRing({
    //         total: res.data.data.totalMap.total,
    //         data: temp,
    //       });
    //     });
    // },
    // 机构查询远程查询
    searchOrgName(query) {
      let url = "/user/mastermechanism/queryMechanismByType";
      // const _this = this
      // if (this.cancel) {
      //   this.cancel()
      // }
      if (this.cancel) {
        this.cancel.abort();
      }
      this.cancel = new AbortController();
      this.$axios
        .get(url, {
          // cancelToken: new this.$axios.CancelToken(function executor(c) {
          //   _this.cancel = c
          // }),
          params: {
            type: "teach_paypal",
            categories: "体育运动",
            source: "体育宝",
            mechanism_name:
              typeof query == "string" && query != "" ? query : null,
            pageSize: 10,
            currentPage: 1,
          },
          signal: this.cancel.signal,
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_name_list = res.data.data;
        })
        .catch((err) => {});
    },
    changeMechanismType(v) {
      this.mechanism_name_list = [];
    },
    // searchMasterName(query) {
    //   let url = "/user/masterInfo/queryByMessage";
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         type: "teach_paypal",
    //         full_name: query,
    //         pageSize: 10,
    //         currentPage: 1,
    //         status: 2,
    //       },
    //     })
    //     .then((res) => {
    //       this.master_name_list = res.data.data.rows;
    //     });
    // },
    // 课程详情页
    toCouDetails(row) {
      this.$router.push({
        name: "couDetails",
        query: {
          type: "mec",
          couID: row.id,
          couMec: row.map.masterMechanismEntity.mechanism_name,
        },
      });
    },
    levelName(id) {
      let a = this.allCardLevel.find((item, index, arr) => {
        return item.id == id;
      });
      if (a) {
        return a;
      } else return {};
    },
    getAllCardLevel() {
      let url = "/user/userMemberLevel/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 99,
            platform: "体育宝",
            status: 1,
          },
        })
        .then((res) => {
          this.allCardLevel = res.data.data.rows;
          this.allCardLevel.findIndex;
        });
    },
    handleClose(done) {
      this.if_clear = false;
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDialog = false;
          this.updateDialog = false;
          this.exchangeShow = false;
          this.if_clear = true;
          this.course_num = null;
          this.temp = {};
          this.card_type = [];
          this.form = {};
          done();
        })
        .catch((_) => {});
    },
  },
  mounted() {
    this.ks = window.screen.width < 768;
    this.getCourseLsit();
    this.getCateList();
    this.getAgeType();
    this.getSchoolAgeType();
    // this.getAllCardLevel()
  },
};
</script>

<style lang="less">
.xgk {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .xgk1 {
    width: 35%;
    @media screen and(max-width: 768px) {
      width: 100%;
    }
  }
}
.el-table thead tr th {
  background-color: #eaeaea !important;
}
</style>
